<template>
  <div class="wrapper">
    <div class="header" :class="navid == 1 && is_grey == 1 ? 'bgh' : ''">
      <div class="bg-F5F6F7">
        <div class="max-w1200 mrauto ptb9 flex j-sb a-center">
          <div class="flex a-end">
            <div class="w14h23 mr10">
              <img src="../assets/img/add.png" alt="" class="w14h23" />
            </div>
            <div class="fs-18 col-333333 mr12 cup" @click="getPosition">{{ city }}</div>
            <div class="col-E23234 fs-12 mr15 cup" @click="dialogVisible = true">
              [<span class="cutadd">切换</span>]
            </div>
            <div class="fs-12 col-666666">欢迎来到佰铺邦！</div>
          </div>
          <div class="flex a-center">
            <div class="flex fs-14 col-333333 mr26">
              <div class="mr15 cup hr-col" @click="cutlogin" v-if="!userInfo">登录</div>
              <div class="mr15 cup hr-col" @click="outlogin" v-else>
                <span v-if="user_type == 0">普通用户：</span>
                <span v-if="user_type == 1">会员：</span>
                <span v-if="user_type == 2">商家：</span>
                <span v-if="user_type == 3">区域代理：</span>
                <span v-if="user_type == 5">合伙人：</span>
                <span v-if="user_type == 4">合伙人A：</span>
                <span v-if="user_type == 6">合伙人C：</span>{{ userInfo.nickname }}
              </div>
              <!-- <div class="cup hr-col">注册</div> -->
            </div>
            <div class="flex a-center fs-12 col-666666">
              <div class="col-C6C9CD fs-18 mr23">|</div>
              免费热线：<span class="fs-18 col-E23234">{{ freecall }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="max-w1200 h80 mrauto mt20 cup">
        <!-- <img src="../assets/img/zf.png" alt="" class="max-w1200 h80" /> -->
        <img :src="topgg" alt="" class="max-w1200 h80" />
      </div>
      <div class="max-w1200 mrauto mt17 mb40 flex a-center j-sb">
        <div class="flex a-center">
          <div class="mr15 w248h65">
            <img src="../assets/img/logot.png" alt="" class="w248h65" />
            <!-- <img src="../assets/img/logo.png" alt="" /> -->
          </div>
          <div>
            <!-- <img src="" alt="" /> -->
            <!-- <img src="../assets/img/bawo.png" alt="" /> -->
          </div>
        </div>
        <div>
          <!-- <div class="mb15">
            <ul class="flex fs-12 col-666666">
              <li
                class="mr15 cup sj-h"
                :class="souid == 1 ? 'actsj' : ''"
                @click="soucut(1)"
              >
                转让出租
              </li>
              <li
                class="mr15 cup sj-h"
                :class="souid == 2 ? 'actsj' : ''"
                @click="soucut(2)"
              >
                招商出售
              </li>
              <li class="cup sj-h" :class="souid == 3 ? 'actsj' : ''" @click="soucut(3)">
                找店选址
              </li>
            </ul>
          </div> -->
          <div class="flex a-center">
            <div class="w338 h44 bor-CC0000 plr4">
              <input type="text" name="" v-model="keyword" class="input w338 h44" />
            </div>
            <div
              class="w101 h44 flex a-center j-center bor-CC0000 bg-CC0000 col-ffffff fs-16 cup"
              @click="searchInput"
            >
              商铺搜索
            </div>
          </div>
        </div>
      </div>
      <div class="bg-CC0000 per-w100">
        <div class="flex a-center j-sb max-w1200 mrauto">
          <ul class="flex a-center fs-16 col-ffffff cup">
            <li
              class="ptb14lr35"
              :class="navid == item.id ? 'bg-F06464' : ''"
              @click="navcut(item)"
              v-for="(item, index) in navList"
              :key="index"
            >
              {{ item.name }}
            </li>
          </ul>
          <div class="flex a-center">
            <div class="flex fs-16 col-ffffff cup mr20" @click="fabu(1)">
              <div class="mr10">
                <img class="wh20" src="../assets/img/fabu.png" alt="" />
              </div>
              发布信息
            </div>
            <div class="flex fs-16 col-ffffff cup" @click="fabu(2)">个人中心</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <router-view @navid="navid" />
    </div>
    <div class="footer per-w100 bg-EEEEEE" :class="navid == 1 && is_grey == 1 ? 'bgh' : ''">
      <div class="max-w1200 mrauto">
        <div class="pt50b30l50r76 borb-CBCBCB flex a-center j-sb">
          <div>
            <div class="mb15 w257 h71">
              <img src="../assets/img/logoh.jpg" alt="" class="w257 h71" />
            </div>
            <div class="flex a-center j-center fs-20 col-D8252D">
              <div class="mr7">
                <img src="../assets/img/phone.png" alt="" />
              </div>
              {{ freecall }}
            </div>
          </div>
          <div class="flex a-center">
            <div class="fs-16 col-333333 mr71">
              <ul class="flex a-center w338 j-sb mb15">
                <router-link
                  :to="{ path: '/indexer', query: { navid: 2 } }"
                  target="_blank"
                  >转让出租</router-link
                >
                <router-link
                  :to="{ path: '/indexer', query: { navid: 3 } }"
                  target="_blank"
                  >招商出售</router-link
                >
                <router-link
                  :to="{ path: '/indexer', query: { navid: 4 } }"
                  target="_blank"
                  >找店选址</router-link
                >
              </ul>
              <ul class="flex a-center j-sb w338 mb15">
                <router-link
                  :to="{ path: '/indexer', query: { navid: 7 } }"
                  target="_blank"
                  >二手货品</router-link
                >
                <router-link
                  :to="{ path: '/Nearby', query: { navid: 6 } }"
                  target="_blank"
                  >附近商家</router-link
                >
                <router-link :to="{ path: '/about', query: { navid: 5 } }" target="_blank"
                  >关于我们</router-link
                >
              </ul>
            </div>
            <div class="flex a-center">
              <div class="w122h122 mr26">
                <img src="../assets/img/xiaochegnxu.jpg" alt="" class="w122h122" />
              </div>
              <div class="w122h122">
                <img src="../assets/img/erweima.jpg" alt="" class="w122h122" />
              </div>
            </div>
          </div>
        </div>
        <div class="pt23 pb100">
          <div class="fs-14 col-666666 w847 text-center mrauto">
            公司电话：{{ company_phone }} 公司地址：{{ company_address }} 广告联系：{{ company_call }}
            广告投放电话：{{ company_add }} 版权所有 © 佰铺邦 商铺信息网 备案号：<a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              >蜀ICP备18021916号</a
            ><a href="http://www.beian.gov.cn/portal/registerSystemInfo"
              ><img src="../assets/img/beian2.png" alt="" class="wh20" /> 川公网安备
              51130202000319号</a
            >
          </div>
          <div class="w372 h51 mrauto mt23">
            <img src="../assets/img/beian.png" alt="" class="w372 h51" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="选 择 城 市" :visible.sync="dialogVisible" :width="isMobile()?'80%':'30%'">
      <div class="flex a-center f-w">
        <el-tag
          type="info"
          v-for="(item, index) in cityList"
          :key="index"
          @click="selecity(item.city)"
          class="mr15 mb15 cup"
          >{{ item.city }}</el-tag
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { json } from "body-parser";
import { city_list, logout, get_add, set_grey, get_city_val, get_pc_val, configParams } from "../utils/api.js";
export default {
  metaInfo() {
    return {
      title: this.PageTitle,
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.baipubang.com',
        },
        {
          property: "og:image",
          content: 'https://www.baipubang.com/images/fac.ico',
        },
      ],
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        this.get_grey();
        let userInfo = window.localStorage.getItem("userInfo");
        this.user_type = window.localStorage.getItem("user_type");
        if (userInfo) {
          this.userInfo = JSON.parse(userInfo);
        }

        if (this.$route.query.navid) {
          this.navid = this.$route.query.navid;
        }
        if (this.$route.query.keyword) {
          this.keyword = this.$route.query.keyword;
        }
      },
    },
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",

      freecall:'15386628973',//免费热线
      topgg:'',//导航栏广告
      company_phone:'0817-2128009',//公司电话
      company_address:'南充市顺庆区文化路89号',//公司地址
      company_call:'0817-2128009',//广告联系
      company_add:'18582135678',//广告投放电话
      xcx:'../assets/img/xiaochegnxu.jpg',//小程序
      ewm:'../assets/img/erweima.jpg',//二维码

      is_grey:0,
      user_type: "",
      lat: "",
      province: "",
      lng: "",
      city: "",
      city_area: "",
      cityList: [],
      dialogVisible: false,
      keyword: "",
      souid: 1,
      navid: 1,
      navList: [
        {
          id: 1,
          name: "首页",
          path: "/indexer",
        },
        {
          id: 2,
          name: "转让出租",
          path: "/indexer",
        },
        {
          id: 3,
          name: "招商出售",
          path: "/indexer",
        },

        {
          id: 4,
          name: "找店选址",
          path: "/indexer",
        },
        {
          id: 7,
          name: "二手货品",
          path: "/indexer",
        },
        {
          id: 6,
          name: "附近商家",
          path: "/Nearby",
        },
        {
          id: 5,
          name: "关于我们",
          path: "/about",
        },
      ],
      userInfo: "",
    };
  },
  beforeMount(){
    this.getSeo();
  },
  mounted() {
    console.log('---',window.orientation);
    console.log(navigator.userAgent);
    // if (this.isMobile()) {
    //   console.log('移动端');
    //   this.toH5();
    // } else {
    //   console.log('PC端');
    // }


    this.getPCtop();
    this.get_grey();
    if(!this.$route.query.navid){
       this.$router.push({
        path: "/indexer",
        query: { navid: 1 },
      });
    }
    //  this.$router.push({
    // path: "/indexer",
    //   query: { navid: 1 },
    // });
    let userInfo = window.localStorage.getItem("userInfo");
    this.user_type = window.localStorage.getItem("user_type");
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo);
    }
    if (this.$route.query.navid) {
      this.navid = this.$route.query.navid;
      if (this.$route.query.keyword) {
        this.keyword = this.$route.query.keyword;
      }else{
        this.keyword = ''
      }
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }else{
      this.keyword = ''
    }
    let city = window.localStorage.getItem("city");
    if (city) {
      this.city = city;
      this.getCityVal(this.city);
    } else {
      this.getLocation();
    }
    this.getcity();
  },
  methods: {
    // 跳转到h5
    toH5(){
      window.location.href = 'https://web.baipubang.com/h5';
    },
    // 判断是否为移动设备
    isMobile() {
      // 获取userAgent字符串
      var userAgent = navigator.userAgent || navigator.vendor || window.opera
 
      // 使用正则表达式来检查userAgent字符串中是否包含某些关键字
      // 这些关键字通常与移动设备相关
      var mobile = /windows phone|iphone|ipad|ipod|android|blackberry|mini|windows ce|palm/i.test(
        userAgent.toLowerCase()
      )
      return mobile;
    },
    getSeo(){
      let obj = {
        fields:'seo',
      }
      configParams(obj)
        .then((res)=>{
          console.log('seo',res.data);
          this.PageTitle = res.data.seo&&res.data.seo.title?res.data.seo.title:'佰铺邦'
          this.PagekeyWords = res.data.seo&&res.data.seo.keywords?res.data.seo.keywords:'佰铺邦商铺信息网'
          this.PageDescription = res.data.seo&&res.data.seo.describe?res.data.seo.describe:'佰铺邦商铺信息网'
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPCtop(){
      get_pc_val({})
        .then((res) => {
          this.topgg = res.data.top_ad_img
        })
        .catch((err) => {
          console.log(err);
        });
    },
    get_grey() {
      let _this = this;
      set_grey({})
        .then((res) => {
          _this.is_grey = res.data.is_grey;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    outlogin() {
      let that = this;
      this.$confirm("是否要退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logout({})
            .then((res) => {
              console.log(res);
              if (res.code != 200) {
                that.$message.error(res.msg);
                return;
              }
              window.localStorage.removeItem("userInfo");
              window.localStorage.removeItem("uid");
              window.localStorage.removeItem("user_type");
              that.userInfo = "";
              that.$message.success(res.msg);
              setTimeout(() => {
                this.$router.push({
                  path: "/login",
                  query: { navid: 10 },
                });
                // let routeData = this.$router.resolve({
                //   path: "/login",
                //   query: { navid: 10 },
                // });
                // window.open(routeData.href, "_blank");
              }, 1500);
            })
            .catch((err) => {
              console.log(err);
              that.$message.error(err.msg);
            });
        })
        .catch(() => {});
    },
    cutlogin() {
      this.$router.push({
        path: "/login",
        query: { navid: 10 },
      });
      // let routeData = this.$router.resolve({
      //   path: "/login",
      //   query: { navid: 10 },
      // });
      // window.open(routeData.href, "_blank");
    },
    selecity(name) {
      window.localStorage.setItem("city", name);
      this.city = name;
      this.dialogVisible = false;
      this.$router.push({
        path: "/indexer",
        query: { navid: 1, city: this.city },
      });
      // let routeData = this.$router.resolve({
      //   path: "/indexer",
      //   query: { navid: 1, city: this.city },
      // });
      // window.open(routeData.href, "_blank");
    },
    getcity() {
      city_list({})
        .then((res) => {
          console.log(res);
          this.cityList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fabu(id) {
      if (id == 1) {
        // this.$router.push({
        //   path: "/release",
        //   query: { navid: 10 },
        // });
        let routeData = this.$router.resolve({
          path: "/release",
          query: { navid: 10 },
        });
        window.open(routeData.href, "_blank");
      } else {
        // this.$router.push({
        //   path: "/member",
        //   query: { navid: 10 },
        // });
        let routeData = this.$router.resolve({
          path: "/member",
          query: { navid: 10 },
        });
        window.open(routeData.href, "_blank");
      }
    },
    soucut(id) {
      this.souid = id;
    },
    navcut(item) {
      if (item.id != this.navid) {
        this.navid = item.id;
        this.$router.push({
          path: item.path,
          query: { navid: item.id },
        });
        // let routeData = this.$router.resolve({
        //   path: item.path,
        //   query: { navid: item.id },
        // });
        // window.open(routeData.href, "_blank");
      }
    },
    searchInput() {
      this.navList.forEach((item) => {
        if (this.navid == item.id) {
          if(this.keyword!=''){
            this.$router.push({
              path: item.path,
              query: { navid: item.id, keyword: this.keyword },
            });
          }else{
            this.$router.push({
              path: item.path,
              query: { navid: item.id },
            });
          }
          // let routeData = this.$router.resolve({
          //   path: item.path,
          //   query: { navid: item.id, keyword: this.keyword },
          // });
          // window.open(routeData.href, "_blank");
        }
      });
    },
    // 不同城市电话/地址/二维码内容
    getCityVal(city){
      get_city_val({city:city})
        .then((res)=>{
          console.log('res',res);
          this.freecall = res.data.hot_phone?res.data.hot_phone:''
          this.company_phone = res.data.company_phone?res.data.company_phone:''
          this.company_address = res.data.address?res.data.address:''
          this.company_call = res.data.ad_phone?res.data.ad_phone:''
          this.company_add = res.data.ad_up_phone?res.data.ad_up_phone:''
        })
    },
    //获取定位
    getLocation() {
      var geolocation = new window.qq.maps.Geolocation(
        "56EBZ-EQQEX-ITM4I-TG262-WXVIJ-QKFBT",
        "key_map"
      );
      geolocation.getLocation(this.showPosition, this.errorPosition);
    },
    showPosition(position) {
      console.log(position);
      this.lng = position.lng;
      this.lat = position.lat;
      get_add({
        lat: position.lat,
        lng: position.lng,
      })
        .then((res) => {
          console.log(res);
          this.city = res.data.address_component.city;
          this.province = res.data.address_component.province;
          this.city_area = res.data.address_component.district;
          window.localStorage.setItem("lng", position.lng);
          window.localStorage.setItem("lat", position.lat);
          window.localStorage.setItem("province", res.data.address_component.province);
          window.localStorage.setItem("city", res.data.address_component.city);
          this.getCityVal(this.city);
          window.localStorage.setItem("city_area", res.data.address_component.district);
          window.localStorage.setItem("address", res.data.address);
          this.$router.push({
            path: "/indexer",
            query: { navid: 1, city: position.city },
          });
        })
        .catch((err) => {
          console.log(err);
        });
      // console.log(position.province);
      // let routeData = this.$router.resolve({
      //   path: "/indexer",
      //   query: { navid: 1, city: position.city },
      // });
      // window.open(routeData.href, "_blank");
    },
    errorPosition() {
      this.$message.error("定位失败，正在重新定位请稍后...");

      console.log("定位失败t");
      //继续定位
      this.getLocation();
    },
    getPosition() {
      this.$message("正在重新定位请稍后...");
      this.getLocation();
      //点击一次获取一次
    },
  },
};
</script>

<style lang="scss">
.bgh {
  filter: grayscale(1);
}
a {
  color: #666;
  text-decoration: none;
}
.wrapper {
  .cutadd:hover {
    border-bottom: 1px solid #e23234;
  }

  .hr-col:hover {
    color: #cc0000;
  }

  .sj-h:hover {
    position: relative;
    color: #cc0000;
  }

  .sj-h:hover:after {
    content: "";
    transform: rotate(45deg);
    bottom: -140%;
    left: 45%;
    position: absolute;
    width: 0.625rem;
    height: 0.625rem;
    border: 1px solid #cc0000;
    border-color: #cc0000 transparent transparent #cc0000;
  }

  .sj-h:hover:before {
    content: "";
    width: 1rem;
    height: 0.0625rem;
    background: #ffffff;
    position: absolute;
    bottom: -103%;
    left: 41%;
  }

  .actsj {
    position: relative;
    color: #cc0000;
  }

  .actsj:after {
    content: "";
    transform: rotate(45deg);
    bottom: -140%;
    left: 45%;
    position: absolute;
    width: 0.625rem;
    height: 0.625rem;
    border: 1px solid #cc0000;
    border-color: #cc0000 transparent transparent #cc0000;
  }

  .actsj:before {
    content: "";
    width: 1rem;
    height: 0.0625rem;
    background: #ffffff;
    position: absolute;
    bottom: -103%;
    left: 41%;
  }

  ul a {
    text-decoration: none;
    color: #333333;
  }
}
</style>
